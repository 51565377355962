import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import SEO from '../components/seo';
import '../styles/theme.scss';

export const query = graphql`
  query($slug: String!) {
    contentfulRealProject(slug: { eq: $slug }) {
      projectName
      category
      role
      timeline
      technologiesUsed
      organization
      client
      previewLink
      description {
        json
      }
      process {
        json
      }
      learningOutcomes {
        json
      }
      featuredImage {
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
        }
        title
        file {
          url
        }
      }
      images {
        fluid {
          ...GatsbyContentfulFluid_tracedSVG
        }
        title
        file {
          url
        }
      }
    }
  }
`;
const Project = (props) => {
  let description;
  let process;
  let outcomes;

  if (props.data.contentfulRealProject.description) {
    description = (
      <>
        <h3>Description</h3>
        {documentToReactComponents(
          props.data.contentfulRealProject.description.json
        )}
      </>
    );
  }

  if (props.data.contentfulRealProject.process) {
    process = (
      <>
        <h3>Process</h3>
        {documentToReactComponents(
          props.data.contentfulRealProject.process.json
        )}
      </>
    );
  }
  if (props.data.contentfulRealProject.learningOutcomes) {
    outcomes = (
      <>
        <h3>Learning Outcomes</h3>
        {documentToReactComponents(
          props.data.contentfulRealProject.learningOutcomes.json
        )}
      </>
    );
  }
  return (
    <>
      <SEO title="Real World Project | Jimmy Truong" />
      <Container fluid>
        <Row className="pageHeader">
          <Col>
            <h1>{props.data.contentfulRealProject.projectName}</h1>
          </Col>
          <Col>
            <h5>{props.data.contentfulRealProject.category}</h5>
          </Col>
        </Row>
        <Row className="ProjectsContainer">
          <Col lg="6" md="12" className="ProjectInfo">
            <h4>
              Timeline: <span>{props.data.contentfulRealProject.timeline}</span>
            </h4>
            <h4>
              Role: <span>{props.data.contentfulRealProject.role}</span>
            </h4>
            <h4>
              Technologies used:{' '}
              <span>{props.data.contentfulRealProject.technologiesUsed}</span>
            </h4>
          </Col>
          <Col lg="6" md="12" className="ProjectInfo">
            <h4>
              Organization:{' '}
              <span>{props.data.contentfulRealProject.organization}</span>
            </h4>
            {props.data.contentfulRealProject.client ? (
              <h4>
                Client: <span>{props.data.contentfulRealProject.client}</span>
              </h4>
            ) : (
              ''
            )}
          </Col>
        </Row>
        <hr />
        <Row className="ProjectDetail">
          <Col lg="6" md="12">
            {props.data.contentfulRealProject.images ? (
              props.data.contentfulRealProject.images.map((item, index) => (
                <Img
                  key={index}
                  fluid={item.fluid}
                  className="project-img"
                  alt={item.title}
                />
              ))
            ) : (
              <Img
                fluid={props.data.contentfulRealProject.featuredImage.fluid}
              />
            )}
          </Col>
          <Col lg="6" md="12">
            {description}
            {process}
            {outcomes}
            <a
              href={props.data.contentfulRealProject.previewLink}
              className="btn btn-outline-info"
              target="_blank"
              rel="noopener noreferrer"
            >
              Live Preview
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Project;
